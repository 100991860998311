exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-access-denied-jsx": () => import("./../../../src/pages/access-denied.jsx" /* webpackChunkName: "component---src-pages-access-denied-jsx" */),
  "component---src-pages-agendamento-finalizado-jsx": () => import("./../../../src/pages/agendamento-finalizado.jsx" /* webpackChunkName: "component---src-pages-agendamento-finalizado-jsx" */),
  "component---src-pages-agendamento-jsx": () => import("./../../../src/pages/agendamento.jsx" /* webpackChunkName: "component---src-pages-agendamento-jsx" */),
  "component---src-pages-blog-page-jsx": () => import("./../../../src/pages/blogPage.jsx" /* webpackChunkName: "component---src-pages-blog-page-jsx" */),
  "component---src-pages-consultas-medicas-jsx": () => import("./../../../src/pages/consultas-medicas.jsx" /* webpackChunkName: "component---src-pages-consultas-medicas-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-politica-de-privacidade-jsx": () => import("./../../../src/pages/politica-de-privacidade.jsx" /* webpackChunkName: "component---src-pages-politica-de-privacidade-jsx" */),
  "component---src-pages-portal-do-medico-jsx": () => import("./../../../src/pages/portal-do-medico.jsx" /* webpackChunkName: "component---src-pages-portal-do-medico-jsx" */),
  "component---src-pages-portal-do-paciente-jsx": () => import("./../../../src/pages/portal-do-paciente.jsx" /* webpackChunkName: "component---src-pages-portal-do-paciente-jsx" */),
  "component---src-pages-renovacao-de-receita-jsx": () => import("./../../../src/pages/renovacao-de-receita.jsx" /* webpackChunkName: "component---src-pages-renovacao-de-receita-jsx" */),
  "component---src-pages-sobre-mim-jsx": () => import("./../../../src/pages/sobre-mim.jsx" /* webpackChunkName: "component---src-pages-sobre-mim-jsx" */),
  "component---src-pages-telemedicina-jsx": () => import("./../../../src/pages/telemedicina.jsx" /* webpackChunkName: "component---src-pages-telemedicina-jsx" */),
  "component---src-pages-termos-de-servico-jsx": () => import("./../../../src/pages/termos-de-servico.jsx" /* webpackChunkName: "component---src-pages-termos-de-servico-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */)
}

