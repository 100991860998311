// Import global styles
import './src/styles/global.css';
import { injectSpeedInsights } from '@vercel/speed-insights';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import loadImageDimensions from './src/utils/loadImageDimensions';

// Inject Speed Insights
export const onClientEntry = () => {
  injectSpeedInsights();

  // Adicionar Material Icons
  const link = document.createElement('link');
  link.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
  link.rel = 'stylesheet';
  document.head.appendChild(link);
};

// Service Worker update handling
export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    'Este aplicativo foi atualizado. Recarregar para exibir a versão mais recente?'
  );
  if (answer === true) {
    window.location.reload();
  }
};

export const onServiceWorkerInstalled = ({ serviceWorker }) => {
  if (serviceWorker.waiting) {
    serviceWorker.postMessage({ type: 'SKIP_WAITING' });
  }

  serviceWorker.addEventListener('statechange', (e) => {
    if (e.target.state === 'activated') {
      window.location.reload();
    }
  });
};

// Route protection for authenticated users
export const onRouteUpdate = ({ location }) => {
  const userEmail = sessionStorage.getItem('userEmail');

  // Define rotas protegidas
  const protectedRoutes = ['/portal-do-paciente', '/portal-do-medico'];

  // Redireciona usuários não autenticados
  if (protectedRoutes.includes(location.pathname) && !userEmail) {
    window.location.href = '/login';
  }
};

// Wrapper component to adjust image dimensions and include ToastContainer
function Wrapper({ element }) {
  useEffect(() => {
    const adjustImageDimensions = async () => {
      const images = document.querySelectorAll('img');
      for (const img of images) {
        if (!img.hasAttribute('width') || !img.hasAttribute('height')) {
          const dimensions = await loadImageDimensions(img.src);
          img.setAttribute('width', dimensions.width);
          img.setAttribute('height', dimensions.height);
        }
      }
    };

    adjustImageDimensions();
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {element}
    </>
  );
}

export const wrapPageElement = ({ element, props }) => (
  <Wrapper element={element} {...props} />
);
